// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".UsermanagmentStyle-module__EdituploadFileLabel___1EEr0{background:linear-gradient(94.67deg, #5f78ff -1.19%, #7185f2 53.94%, #8d91ff 102.59%);border-radius:20px;padding:9px 25px;font-weight:800;font-size:13px;line-height:22px;width:26%;text-align:center;color:#ffffff;cursor:pointer}.UsermanagmentStyle-module__closeFileIcon___29HLB{margin-left:8px;color:red;line-height:44px;cursor:pointer}.UsermanagmentStyle-module__Editnofilechosen___3d4ch{margin-left:12px;font-size:12px;line-height:44px;text-align:center;color:#9EA6C8;cursor:auto;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;max-width:8rem;display:inline-block}\n", "",{"version":3,"sources":["/home/siva/ch-job-marketplace/releases/20240916100908/app/javascript/components/pages/Admin/styles/UsermanagmentStyle.module.scss"],"names":[],"mappings":"AACA,wDACI,qFAAwF,CACxF,kBAAmB,CACnB,gBAAiB,CACjB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,SAAU,CACV,iBAAkB,CAClB,aAAc,CACZ,cAAe,CACpB,kDAEC,eAAiB,CACjB,SAAU,CACV,gBAAiB,CACjB,cAAe,CAChB,qDAEG,gBAAiB,CACjB,cAAe,CACf,gBAAiB,CACjB,iBAAkB,CAClB,aAAc,CACd,WAAY,CACZ,kBAAmB,CACnB,sBAAuB,CACvB,eAAgB,CAChB,cAAe,CACf,oBAAqB","file":"UsermanagmentStyle.module.scss","sourcesContent":["@import '../../../../styles/var';\n.EdituploadFileLabel{\n    background: linear-gradient( 94.67deg, #5f78ff -1.19%, #7185f2 53.94%, #8d91ff 102.59% );\n    border-radius: 20px;\n    padding: 9px 25px;\n    font-weight: 800;\n    font-size: 13px;\n    line-height: 22px;\n    width: 26%;\n    text-align: center;\n    color: #ffffff;\n      cursor: pointer;\n}\n.closeFileIcon{\n  margin-left: 8px ;\n  color: red;\n  line-height: 44px;\n  cursor: pointer;\n} \n.Editnofilechosen{\n    margin-left: 12px;\n    font-size: 12px;\n    line-height: 44px;\n    text-align: center;\n    color: #9EA6C8;\n    cursor: auto;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    max-width: 8rem;\n    display: inline-block;\n}"]}]);
// Exports
exports.locals = {
	"EdituploadFileLabel": "UsermanagmentStyle-module__EdituploadFileLabel___1EEr0",
	"closeFileIcon": "UsermanagmentStyle-module__closeFileIcon___29HLB",
	"Editnofilechosen": "UsermanagmentStyle-module__Editnofilechosen___3d4ch"
};
module.exports = exports;
