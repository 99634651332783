import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Card from '../shared/Card'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import Button from '../shared/Button'
import P from '../shared/P'
import linkedinIcon  from '../../../../../assets/images/icon-linkedin.png'
import AsyncSelect from "react-select/async"
import CandidateSkills from '../../../common/CandidateSkills/CandidateSkills'
import Select from 'react-select'
import { DisplayPagination } from '../styles/AdminDashboard.styled'
import { Row,Col,Spinner,Modal,Popover,OverlayTrigger } from 'react-bootstrap'
import '../styles/Jobs.scss'
import { Formik } from 'formik'
import SearchTextBox from '../shared/SearchTextBox'
import { capitalizeFirstLetter } from '../../../../utils'
import isEmpty from 'lodash.isempty'
import DataTable from "react-data-table-component-footer"
import   '../styles/DataTableCustom.scss'
import feather from 'feather-icons'
import CandidateUpload from './CandidateUpload'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
const allMsgSequnce = [
    { value: 1, label: 'Default - Text' },
    // { value: 8, label: 'Social Network 2' },
    // { value: 10, label: 'Ruby on Rails Engineer' },
    // { value: 11, label: 'Senior Marketing Expert' },
    { value: 3, label: '$1,000/$100 - Calendar Invite' },
    { value: 2, label: 'Sr. Recruiter Calendar' },
    { value: 7, label: 'Social Network' },
    { value: 9, label: 'Sales Representative' },
    // { value: 4, label: '$7,000 - Over Text' },
    // { value: 5, label: '$1,000/$100 - Over Text' },
    { value: 6, label: 'SMS Screening - Process' }
]
const JobsIndex = () => {
    var active_page = window.localStorage.getItem('activePage')
    var query = window.localStorage.getItem('query')
    var status = window.localStorage.getItem('status')
    var organization = window.localStorage.getItem('organization')
    var owner = window.localStorage.getItem('owner')
    var wishlist = window.localStorage.getItem('wishlist')
    var jobOrigin = window.localStorage.getItem('jobOrigin')
    var isAutomation = window.localStorage.getItem('isAutomation')
    var missingConfig = window.localStorage.getItem('missingConfig')
    var text_sourcing = window.localStorage.getItem('text_sourcing')
    let txt_source = JSON.parse(text_sourcing)
    const [activePage, setActivePage] = useState(parseInt(active_page) || 1)
    const [pageCount, setPageCount] = useState(0)
    const [jobs, setJobs] = useState([])
    const [searchTerm, setSearchTerm] = useState(query || '')
    const [isLoading, setLoading] = useState(false)
    let organization_data = organization && JSON.parse('['+organization+']')
    let owner_data = owner && JSON.parse(owner)
    const [selectedOrganization, setSelectedOrganization]  = useState(organization_data ? organization_data.map( ({value, label}) =>value) :[])
    const [filterOrganization, setFilterOrganization] = useState(organization_data && organization_data || [])
    const [filterOwner, setFilterOwner] = useState(owner_data && owner_data)
    const [selectedStatus, setSelectedStatus]  = useState('all')
    const [member_options, setOptions] = useState([])
    const [owner_options, setOwnerOptions] = useState([])
    const [selectedOwner, setSelectedOwner] = useState(owner_data && owner_data.value)
    const [selected, setSelected] = useState({value: status || 'all', label: status == 'onhold' ? 'On Hold' :  status ? capitalizeFirstLetter(status) : 'All'})
    const [selectedWishlist, setSelectedWishlist] = useState({value: wishlist || 'all', label: (wishlist) &&  (wishlist == 'all' ? 'All' : ((wishlist == 'true' ? 'Yes' : 'No') ) || 'All')})
    const [selectedOrigin, setSelectedOrigin] = useState({value: jobOrigin || 'all', label: (jobOrigin) &&  (jobOrigin == 'all' ? 'All' : ((jobOrigin == 'true' ? 'Yes' : 'No') ) || 'All')})
    const [selectedAutomation, setSelectedAutomation] = useState({value: isAutomation || 'all', label: (isAutomation) &&  (isAutomation == 'all' ? 'All' : ((isAutomation == 'true' ? 'Yes' : 'No') ) || 'All')})
    const [selectedMissing, setSelectedMissing] = useState({value: missingConfig || 'all', label: (missingConfig) &&  (missingConfig == 'all' ? 'All' : ((missingConfig == 'true' ? 'Yes' : 'No') ) || 'All')})
    const [totalJobs,setTotalJobs] = useState(0)
    const [currentCounts,setCurrentCounts] = useState(0)
    const [startRecord, setStartRecord] = useState(0)
    const [loading, setLoader] = useState(true)
    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    const [selectedWishlistSourcing, setSelectedWishlistSourcing]  = useState(wishlist || 'all')
    const [selectedJobOrigin, setSelectedJobOrigin]  = useState(jobOrigin || 'all')
    const [selectedIsAutomation, setSelectedIsAutomation]  = useState(isAutomation || 'all')
    const [sortField, setSortField] = useState('jobs.created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [perPage, setPerPage] = useState(25)
    const [show,setShow] = useState(false)
    const [selectJob,setSelectJob] = useState(false)
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
    const [showCopyConfirmation, setShowCopyConfirmation] = useState(false)
    const [showFormSequnce, setShowFormSequnce] = useState(false)
    const [listMixmaxAc, setListMaxmaxAc] = useState([])
    const [selectedMixMaxAc, setSelectedMixMaxAc] = useState()
    const [defaultMixMaxAc, setDefaultMixMaxAc] = useState()
    const [sequnceList, setSequnceList] = useState([])
    const [selectedSequnce, setSelectedSequnce] = useState()
    const [defaultSequnce, setDefaultSequnce] = useState()
    const [validationError, setValidationError] = useState({})
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)

    const [defaultMsgSeq, setDefaultMsgSeq] = useState()
    const [showMsgFormSequnce, setShowMsgFormSequnce] = useState(false)
    const [selectedMsgSequnce, setSelectedMsgSequnce] = useState()
    const [msgSequnceList, setMsgSequnceList] = useState(allMsgSequnce)
    const [selectedMsgSequnceDefault,setSelectedMsgSequnceDefault] = useState(0)
    const [selectedsequnceObj, setSelectedSequnceObj] = useState([] || { value: 0, label: 'Select Text Sequence' })
    useEffect(() => {
        feather.replace()
    })

    useEffect(() => {
        
    },[])
    const getTextSquence = (id)=>{
        let a = allMsgSequnce.map((msg)=>{
            if(id == msg.value){
                return msg.label.replace(/,/g, '');
            }
        });
        return a;
    }

    const fetchData = (clearFlag=false) => {
        setLoader(true)
        const url = `/admin/jobs.json`
        window.localStorage.setItem('activePage',activePage)
        makeRequest(url, 'get',  {params: {page: (activePage), query: query, sequne_text:selectedMsgSequnceDefault, status: status, organization: selectedOrganization, owner: selectedOwner, wishlist: selectedWishlistSourcing, jobOrigin: selectedJobOrigin, isAutomation:selectedIsAutomation, missingConfig:selectedMissing, sortField: sortField, sortDirection: sortDirection }}, {   
            contentType: 'application/json',
            loadingMessage: 'Fetching...',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                setTotalJobs(res.data.total_count)
                let sr_no = (activePage - 1) * res.data.per_page
                setJobs(
                    res.data.jobs.map((job) => (sr_no = sr_no + 1,{
                        sr_no: sr_no,
                        id: job.id,
                        name:(<a href={`/admin/jobs/${job.id}`}>{capitalize(job.name)}</a>),
                        jname: capitalize(job.name),
                        skills:  (job.skills != '') ? <CandidateSkills skills = {job.skills} /> :job.skills,
                        organization: (<a href={`/admin/organizations/${job.organization_id}`}>{job.organization_name}</a>),
                        organization_name: job.organization_name,
                        expiry_date:  moment(job.expiry_date).format('MMM DD, YYYY'),
                        created_at: moment(job.created_at).format('MMM DD, YYYY'),
                        status:  (<label style={{color: (job.status == "closed" || job.status == "expired") ? "red" :""  }}>{job.status == 'onhold' ? 'On Hold' :capitalize(job.status)}</label>),
                        jstatus: capitalize(job.status),
                        location: job.location,
                        create_lead: (job.create_lead === true) ? 'True' : 'False',
                        organization_id: job.organization_id,
                        full_name: (job?.first_name) ? <div style={{display: 'flex', flexDirection: 'column'}}><span>{job?.first_name} {job?.last_name}</span><small>{job.email}</small></div> : '',
                        wishlist_sourcing: (job.source_at) ? 'Yes' : 'No',
                        msg_squence: getTextSquence(job?.msg_sequence),
                        linkedin_url: job?.linkedin_url ? <a target='_blank' href={job?.linkedin_url}>
                        <img style={{width:'20px', height:'20px'}}  src={linkedinIcon} /></a>
                         : '',
                        actions: (<div className='w-100 d-inline-flex justify-content-around'>
                            <a  href={`/admin/jobs/${job.id}/edit`} title="Edit" className='pr-2'><i data-feather="edit-2" /></a>
                            <a  onClick={() =>showConfirmCopy(job)} href={`javascript::void()`} title="Copy" className='pr-2'><i data-feather="copy" /></a>
                            <a  onClick={() =>showConfirmDelete(job)} title="Delete" href={`javascript::void()`} className='pr-2'><i data-feather="trash-2" /></a>
                            <a  onClick={() => uploadCandidate(job)} title="Upload Candidate" href={`javascript::void()`} className='pr-2'><i data-feather="plus-circle" /></a>
                            <a  onClick={() =>showSequnceForm(job)} title="Mixmax Sequnce Id" href={`javascript::void()`} className='pr-2'><i data-feather="codesandbox" /></a>
                            <a  onClick={() =>showMsgSequnce(job)} title="Message Sequnce" href={`javascript::void()`} ><i data-feather="message-circle" /></a>
                      </div>)
                    }))
                )
                setPageCount(res.data.total_pages)
                setCurrentCounts(res.data.current_counts)
                setStartRecord((activePage - 1) * res.data.per_page)
                setLoader(false)
            },
        })
    }

    const showSequnceForm = (job) => {
        // Reset all state
       
        setListMaxmaxAc([])
        setSelectedMixMaxAc()
        setDefaultMixMaxAc()
      
        setSequnceList([])
        setSelectedSequnce()
        setDefaultSequnce()

        setSelectJob(job)
        getMixmaxAcoount(job)
        if(job.mixmax_config_id != ''){
            getListSequnce(job.mixmax_config_id,job.mixmax_sequnce_id)
            setSelectedMixMaxAc(job.mixmax_config_id)
            setSelectedSequnce(job.mixmax_sequnce_id)
        }
        setShowFormSequnce(true)     
    }

    const showMsgSequnce = (job) => {
        // Reset all state
        // setDefaultMsgSeq()
        setSelectedMsgSequnce()

        setSelectJob(job)
        setSelectedMsgSequnce(job.msg_sequence)
        setDefaultMsgSeq(job?.msg_sequence && msgSequnceList.filter(el => el.value === job.msg_sequence).map(msg_sequence => ({ value: msg_sequence.value, label: msg_sequence.label })))
        setShowMsgFormSequnce(true)     
    }

    const  columns= [
        {
            name: 'No.',
            selector: row => row.sr_no,
            maxWidth: '5%',
            minWidth: '5%',
        },
        {
            name: 'Job Name',
            selector: row => row.name,
            cell: (row) => <div title={row.jname}>{row.name}</div>,
            wrap:true,
            maxWidth: '9%',
            minWidth: '9%',
            sortable: true,
            sortField: 'jobs.name',
        },
        {
            name: 'Primary Owner',
            selector: row => row.full_name,
            wrap:true,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'users.first_name',
        },
        {
            name: 'Skills',
            selector: row => row.skills,
            wrap:true,
            maxWidth: '13%',
            minWidth: '13%',
        },
        {
            name: 'Organization',
            selector: row => row.organization,
            cell: (row) => <div title={row.organization_name}>{row.organization}</div>,
            wrap:true,
            maxWidth: '10%',
            minWidth: '10%',
            sortable: true,
            sortField: 'organizations.name',
        },
        {
            name: 'Location',
            selector: row => row.location,
            cell: (row) => <div title={row.location}>{row.location}</div>,
            // maxWidth: '4%',
            minWidth: '8%',
        },
        // {
        //     name: 'Sourcing Criteria',
        //     selector: row => row.wishlist_sourcing,
        //     cell: (row) => <div title={row.wishlist_sourcing}>{row.wishlist_sourcing}</div>,
        //     // maxWidth: '4%',
        //     minWidth: '6%',
        // },
        {
            name: 'Linkedin Url',
            selector: row => row.linkedin_url,
            sortField: 'linkedin_url',
            sortable: true,
            // maxWidth: '4%',
            minWidth: '3.5%',
        },
        {
            name: 'Expiry On',
            selector: row => row.expiry_date,
            cell: (row) => <div title={row.expiry_date}>{row.expiry_date}</div>,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'jobs.expiry_date',
        },
        {
            name: 'Created On',
            selector: row => row.created_at,
            cell: (row) => <div title={row.created_at}>{row.created_at}</div>,
            wrap:true,
            maxWidth: '7%',
            minWidth: '7%',
            sortable: true,
            sortField: 'jobs.created_at',
        },
        {
            name: 'Status',
            selector: row => row.status,
            wrap:true,
            cell: (row) => <div title={row.jstatus}>{row.status}</div>,
            maxWidth: '5%',
            minWidth: '5%'
        },
        // {
        //     name: 'Create Lead',
        //     selector: row => row.create_lead,
        //     cell: (row) => <div title={row.create_lead}>{row.create_lead}</div>,
        //     maxWidth: '4%',
        //     minWidth: '4%',
        // },
        // {
        //     name: 'MSG Sequence',
        //     selector: row => row.msg_squence,
        //     maxWidth: '7%',
        //     minWidth: '7%',
        //     cell: (row) => <div title={row.msg_squence}>{row.msg_squence}</div>,
        // },
        {
            name: 'Action',
            selector: row => row.actions,
            maxWidth: '13.5%',
            minWidth: '13.5%',
        },
    ]

    useEffect(() => {
        fetchData()
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [activePage,selectedStatus,selectedOrganization, selectedMsgSequnceDefault, selectedOwner,selectedWishlistSourcing,selectedJobOrigin,selectedIsAutomation,selectedMissing,sortDirection,sortField])

    const deleteJobs = async () => {
        setShowRemoveConfirmation(false)
        let id = selectJob.id
        const url = `/admin/jobs/${id}`
        await makeRequest(url, 'delete', '', {
            createSuccessMessage: () => 'Job deleted successfully',
            createErrorMessage: (e) => e.response.data.msg,
            onSuccess: () => {      
                setTimeout(()=>{
                    fetchData()
                },2000)
            },      
        })      
    }
    const copyJob = async () => {
        setShowCopyConfirmation(false)
        let id = selectJob.id
        const url = `/admin/jobs/${id}/copy`
        await makeRequest(url, 'get', '', {
            createSuccessMessage: () => 'Job copy successfully',
            createErrorMessage: (e) => e.response.data.msg,
            onSuccess: (res) => {      
                if(res.data.success == true){
                    window.location.href="/admin/jobs/"+ res.data.job.id +"/edit"
                }
            },      
        })      
    }       

    const uploadCandidate = (job) => {
        setShow(true)
        setSelectJob(job)
    }

    const addJobs = () => {
        window.location.href = '/admin/jobs/new'
    }

    const loadOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/admin/organizations/fetch_organization?search=${inputValue}`)
        .then((res) => res.json())
        .then((res) => {
          let {organizations} = res
          setLoading(false)
          setOptions([...organizations.map((organization) => ({ value: organization.id, label: organization.name }))]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(member_options);
    }

    const loadOwnerOptions = (inputValue, callback) => {
        setLoading(true)
        fetch(`/admin/jobs/get_job_owner?search=${inputValue}`)
        .then((res) => res.json())
        .then((res) => {
          let {owners} = res
          setLoading(false)
          setOwnerOptions([...owners.map((owner) => ({ value: owner.id, label: `${owner.first_name} ${owner.last_name}` }))]);
        })
        .catch((err) => console.log("Request failed", err));
        callback(owner_options);
    }

    const handleOwnerOption = (selectedOptions) => {
        setSelectedOwner(selectedOptions && selectedOptions.value)
        setActivePage(1)
        window.localStorage.setItem('activePage',1)
        window.localStorage.setItem('owner', JSON.stringify(selectedOptions && selectedOptions || ''))
    }
    
    const handleSelectOption = (selectedOptions) => {
        console.log(selectedOptions)
        setSelectedOrganization([...selectedOptions.map( ({value, label}) =>value)])
        // setFilterOrganization([...selectedOptions.map( ({value, label}) => ([...{'value':value, 'label':label}]))])
        setActivePage(1)
        window.localStorage.setItem('activePage',1)
        window.localStorage.setItem('organization',[...selectedOptions.map( ({value, label}) => JSON.stringify({'value':value, 'label':label}))])
    }
    const handleSelectStatus = (selectedval) =>{
        setActivePage(1)
        window.localStorage.setItem('activePage',0)
        setSelectedStatus(selectedval.value)
        window.localStorage.setItem('status',selectedval.value)
    }
    const handleSelectedWishlistSourcing = (selectedval) => {
        setActivePage(1)
        window.localStorage.setItem('activePage',0)
        setSelectedWishlistSourcing(selectedval.value)
        window.localStorage.setItem('wishlist',selectedval.value)
    }
    const handleMissingTemOrNote = (selectedval) => {
        setActivePage(1)
        window.localStorage.setItem('activePage',0)
        setSelectedMissing(selectedval.value)
        window.localStorage.setItem('missingConfig',selectedval.value)
    }
    const  handleSelectedJobOrigin = (selectedval) => { 
        setActivePage(1)
        window.localStorage.setItem('activePage',0)
        setSelectedJobOrigin(selectedval.value)
        window.localStorage.setItem('jobOrigin',selectedval.value)
    }
    const  handleSelectedIsAutomation = (selectedval) => { 
        setActivePage(1)
        window.localStorage.setItem('activePage',0)
        setSelectedIsAutomation(selectedval.value)
        window.localStorage.setItem('isAutomation',selectedval.value)
    }
    const filterOptions = [
        { value: 'all', label: 'All'},
        { value: 'active', label: 'Active' },
        { value: 'expired', label: 'Expired' },
        { value: 'pending', label: 'Pending' },
        { value: 'onhold', label: 'On Hold' },
        { value: 'rejected', label: 'Rejected' },
        { value: 'closed', label: 'Closed' }
    ]
    const filterOptionsWishlist = [
        { value: 'all', label: 'All'},
        { value: 'true', label: 'Yes'},
        { value: 'false', label: 'No'}
    ]

    const onSearchChange = (val) =>{
        setSearchTerm(val)
        window.localStorage.setItem('query', val)
    }

    const onSearchClick = (val) => {
        if(searchTerm != ""){
            setSearchTerm("")
            window.localStorage.setItem('query', "")
            fetchData(true)
        }
    }

    const colourStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'7px',border: 'none',minHeight:'40px' })
    };

    const colourStylesCustom = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'7px',border: 'none',minHeight:'40px',width: '100%' })
    };

    const colourStatusStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'7px',border: 'none',minHeight:'40px' })
    };

    const handleSort = async (column, sortDirection) => {
        if(column.sortField != undefined && sortDirection != undefined){
            setSortField(column.sortField)
            setSortDirection(sortDirection)
        }
    }
    
    const showConfirmDelete = (job) => {
        setSelectJob(job)
        setShowRemoveConfirmation(true)
    }

    const showConfirmCopy = (job) => {
        setSelectJob(job)
        setShowCopyConfirmation(true)
    }

 
      
    const saveSeqeunce = async () => {
        setBtnDisabled(false)
        let errors = {}
        let isFormValid = true
        if(selectedSequnce == '' || selectedSequnce == null || selectedSequnce == 'null'){
            errors["sequnce"] = "Sequence is required field."
            isFormValid = false
        }
    
        if (selectedMixMaxAc == '' || selectedMixMaxAc == null || selectedMixMaxAc == 'null'){
            isFormValid = false
            errors["mixmax_ac"] = "Mixmax account is required field."
        }
        setValidationError(errors)
        setBtnDisabled(isFormValid)
    
        if(isFormValid){
            const formData = new FormData()
            for (const key in selectJob) {
                if (key == 'mixmax_sequnce_id') {
                    formData.append(`job[${key}]`, selectedSequnce)
                } else if (key == 'mixmax_config_id') {
                    formData.append(`job[${key}]`, selectedMixMaxAc)
                }else{
                    formData.append(`job[${key}]`, selectJob[key])
                }
            }
            const url = `/admin/jobs/update_sequnce/${selectJob.id}`
            await makeRequest(url, 'put', formData, {
                createSuccessMessage: () => 'Job updated successfully ',
                onSuccess: (res) => {
                    if(res.data.code == 200){
                        setShowFormSequnce(false)
                        fetchData()
                    }
                    setBtnDisabled(false)
                },
            })
        }
    }

    const saveMsgSeqeunce = async () => {
        setBtnDisabled(false)
        let errors = {}
        let isFormValid = true
        if(selectedMsgSequnce == '' || selectedMsgSequnce == null || selectedMsgSequnce == 'null'){
            errors["msg_sequence"] = "Message Sequence is required field."
            isFormValid = false
        }
        setValidationError(errors)
        setBtnDisabled(isFormValid)
        if(isFormValid){
            const formData = new FormData()
            formData.append(`job[msg_sequence]`, selectedMsgSequnce)
            const url = `/admin/jobs/update_msg_sequnce/${selectJob.id}`
            await makeRequest(url, 'put', formData, {
                createSuccessMessage: () => 'Job updated successfully ',
                onSuccess: (res) => {
                    if(res.data.code == 200){
                        setShowMsgFormSequnce(false)
                        fetchData()
                    }
                    setBtnDisabled(false)
                },
            })
        }
    }
      
    const getListSequnce = async (id,sequnce_id) => {
        if(id != ''){
            const url = `/admin/sequences/mixmax/${id}.json`
            setLoadingModal(true)
            try {
                const response = await axios.get(url,'',
                {
                    headers: {
                        'content-type': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                });
                if(response?.data?.api_data){
                    let sequmceArray = (response.data.api_data).map(({ name, _id }) => {
                    return { value: _id, label: name };
                    })
                    setSequnceList(sequmceArray)
                    if(sequnce_id != '' && sequmceArray.length > 0){
                        let selectSequnce = sequmceArray.filter((row)=>(row.value == sequnce_id ))
                        setDefaultSequnce((selectSequnce[0]) || {})
                    }
                }
                setLoadingModal(false) 
            } catch (e) {
                console.error(e)
            }
        }
    }

    const getMixmaxAcoount = async (job) => {
        if(job.id != ''){
            const url = `sequences/mixmax_ac/${job.id}`
            try {
                setLoadingModal(true)
                const res = await axios.get(url,'',
                {
                    headers: {
                        'content-type': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                });
                console.log(res)
                if(res?.data?.list){
                    if(res?.data?.list){
                        let obj = (res?.data?.list).filter((row) => (row.value == job.mixmax_config_id))
                        setListMaxmaxAc(res.data.list)
                        setDefaultMixMaxAc((obj[0]) ? obj[0] : '')
                    }
                    setBtnDisabled(false)
                }
                setLoadingModal(false) 
            } catch (e) {
                console.error(e)
            }
        }
      
    }
    
    const onSelecteMixmax = (e) => {
        setSelectedMixMaxAc(e.value)
        getListSequnce(e.value,'')
        setDefaultMixMaxAc(e)
        setSequnceList([])
        setSelectedSequnce()
        setDefaultSequnce()
        
    }

    const onSelecteSequnce = (e) => {
        setSelectedSequnce(e.value)
        setDefaultSequnce(e)
    }

    const onSelecteMsgSequnce = (e) => {
        setSelectedMsgSequnce(e.value)
        setDefaultMsgSeq(e)
    }

    const onKeyPressSearch = (e) => {
        window.localStorage.setItem('activePage',1)
        setActivePage(1)
        e.key === 'Enter' && fetchData()
    }

    const handleSelectedMsgSequnce = (selectedval) => {
        console.log(selectedval)
        setActivePage(1)
        window.localStorage.setItem('activePage',0)
        setSelectedMsgSequnceDefault(selectedval.value)
        window.localStorage.setItem('text_sourcing',JSON.stringify({label:selectedval.label,value: selectedval.value}))
    }

    return (
        <Card>
            <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ marginBottom: '20px' }}
            >
                <P size="28px" height="38px" color="#1D2447" style={{width:'540px'}}>
                    Jobs List
                </P>
                <div style={{flexGrow:1}}/>
                <Button onClick={addJobs} className="ml-3">
                    Add Job
                </Button>
            </div>
            <Row className="w-100 justify-content-between" style={{ marginBottom: '15px',display: 'flex',alignItems: 'baseline',flexDirection: 'row-reverse' }}>
            <Col xs={12} sm={12} lg={2} style={{display:'contents'}}>
                <div>
                    <Formik>
                        <SearchTextBox type="search" value={searchTerm} name="jobsearch" placeholder="Search job..."  onChange={(e) => onSearchChange(e.target.value)}   onKeyPress={(e) => onKeyPressSearch(e)}   />
                    </Formik>
                    <Button onClick={() =>
                            activePage == 1
                                ? fetchData()
                                : setActivePage(1)
                        } className="" style={{marginLeft: '10px'}}>
                        Search
                    </Button>
                </div>
                </Col>
            </Row>
            <div
                className="d-flex align-items-end w-100 filter_ui_fix"
                style={{ marginBottom: '10px' }}
            >
             
                <Col xs={12} sm={12} lg={2} style={{float:'left'}}>
                    {totalJobs > 0 && loading == false &&
                        <DisplayPagination>Displaying  {(activePage == 1)?1:startRecord + 1} - {currentCounts} of {totalJobs}</DisplayPagination>
                    }
                 </Col>
                 
                 <Col xs={2} sm={12} lg={2}>
                    <span > Select Organization : </span>
                    <AsyncSelect
                        isMulti
                        isLoading={isLoading}
                        isClearable={true}
                        cacheOptions
                        loadOptions={loadOptions}
                        onChange={handleSelectOption}
                        placeholder={'Organization'}
                        defaultValue={filterOrganization}
                        styles={colourStyles}
                        noOptionsMessage={() => 'start typing the name of organization'}
                        className='mt-1'
                    />
                 </Col>
                 <Col xs={12} sm={12} lg={2}>
                    <span> Select Primary Owner : </span>
                    <AsyncSelect
                        isLoading={isLoading}
                        cacheOptions
                        isClearable={true}
                        loadOptions={loadOwnerOptions}
                        onChange={handleOwnerOption}
                        placeholder='Primary owner'
                        defaultValue={filterOwner}
                        styles={colourStyles}
                        noOptionsMessage={() => 'start typing the name of owner'}
                        className='mt-1'
                    />
                 </Col>
                <Col xs={12} sm={12} lg={1}>
                    <span> Select Status : </span>
                    <Select 
                    defaultValue={selected}
                    options={filterOptions} 
                    onChange={handleSelectStatus} 
                    placeholder={'Select Status'} 
                    styles={colourStatusStyles}
                    className='mt-1'
                    />
                </Col>
                <Col xs={12} sm={12} lg={1}>
                    
                            <span>Source Criter. :</span>
                            <Select
                            defaultValue={selectedWishlist}
                            options={filterOptionsWishlist} 
                            onChange={handleSelectedWishlistSourcing} 
                            placeholder={'Sourcing Criteria'} 
                            styles={colourStylesCustom}
                            className='mt-1'
                            />
                </Col>
                <Col xs={12} sm={12} lg={2}>
                    
                            <span>Missing Temp Or Notes :</span>
                            <Select
                            defaultValue={selectedMissing}
                            options={filterOptionsWishlist} 
                            onChange={handleMissingTemOrNote} 
                            placeholder={'Missing Template Or Notes'} 
                            styles={colourStylesCustom}
                            className='mt-1'
                            />
                </Col>
                <Col xs={12} sm={12} lg={1}>
                    <span> Original Job : </span>
                    <Select
                    defaultValue={selectedOrigin}
                    options={filterOptionsWishlist} 
                    onChange={handleSelectedJobOrigin} 
                    placeholder={'Job Original'} 
                    styles={colourStylesCustom}
                    className='mt-1'
                    />
                </Col>
                <Col xs={12} sm={12} lg={1}>
                    <span>Is Automation :</span>
                    <Select
                    defaultValue={selectedAutomation}
                    options={filterOptionsWishlist} 
                    onChange={handleSelectedIsAutomation} 
                    placeholder={'Is Automation'} 
                    styles={colourStylesCustom}
                    className='mt-1'
                    />
                </Col>
                {/* <Col xs={12} sm={12} lg={2}>
                    <span> Text Sequence : </span>
                    <Select
                    defaultValue={selectedsequnceObj}
                    options={allMsgSequnce} 
                    onChange={handleSelectedMsgSequnce} 
                    placeholder={'Text Sequence'} 
                    styles={colourStyles}
                    className='mt-1'
                    />
                </Col> */}
            </div>
            <DataTable
                title={false}
                persistTableHead={true}
                noDataComponent={<p className="text-center p-3">No record found</p>}
                columns={columns}
                data={jobs}
                progressPending={loading}
                progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
                    <Spinner className='spinner' animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </P>}
                onSort={handleSort}
                sortServer
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={totalJobs}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                    noRowsPerPage: true
                }}
                responsive={true}
                onChangePage={page => setActivePage(page)}
            />   

            <Modal
                show={show}
                onHide={() => setShow(false)}
                size={'xl'}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
            >
                <Modal.Body>
                <CandidateUpload
                    job={selectJob}
                    onClose={() => setShow(false)}
                />
                </Modal.Body>
            </Modal>

            {showRemoveConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure you want to delete?"
                    onConfirm={()=>(deleteJobs(),setShowRemoveConfirmation(false))}
                    onCancel={()=>setShowRemoveConfirmation(false)}
                    focusCancelBtn
                    >
                    You will not be able to recover this job!
                </SweetAlert>
            }

            {showCopyConfirmation &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, copy it!"
                    confirmBtnBsStyle="warning"
                    title="Are you sure you want to copy?"
                    onConfirm={()=>(copyJob(),setShowCopyConfirmation(false))}
                    onCancel={()=>setShowCopyConfirmation(false)}
                    focusCancelBtn
                    >
                    You will not be able to recover this job!
                </SweetAlert>
            }
            <Modal
                onHide={() => (setShowFormSequnce(false),setBtnDisabled(false))}
                show={showFormSequnce}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                dialogClassName="modal-60w"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="mx-auto text-center">
                        Set Sequence Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                    <form >
                        <Row className="w-100">
                            <Col  lg="12" md="12" sm="12" xs="12" className="text-center">
                                <h5>{selectJob.name}</h5>
                            </Col>
                        </Row>
                        <Row className="w-100" style={{ marginBottom: '10px', marginTop: '20px' }}>
                            <Col  lg="12" md="12" sm="12" xs="12">
                                <label>Select Mixmax Account :</label>
                                <Select
                                options={listMixmaxAc}
                                value={defaultMixMaxAc}
                                onChange={onSelecteMixmax}
                                name="api_key"
                                style={colourStyles}
                                menuPosition='fixed'
                                placeholder='Select Mixmax Account'
                                isLoading={loadingModal}
                            />
                            {validationError && validationError.mixmax_ac && 
                                <label className='error-validation'>
                                {validationError && validationError.mixmax_ac} 
                                </label>
                            }
                            </Col>
                        </Row>
                        <Row className="w-100" style={{ marginBottom: '10px', marginTop: '20px' }}>
                            <Col  lg="12" md="12" sm="12" xs="12">
                                <label>Select Sequence :</label>
                                <Select
                                options={sequnceList}
                                value={defaultSequnce}
                                onChange={onSelecteSequnce}
                                name="mixmax_sequnce_id"
                                style={colourStyles}
                                menuPosition='fixed'
                                placeholder='Select Sequence'
                                isLoading={loadingModal}
                                />
                                {validationError && validationError.sequnce &&  
                                <label className='error-validation'>
                                    {validationError && validationError.sequnce} 
                                </label>
                                }
                            </Col>
                        </Row>
                        <br/>
                    </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="w-100 text-right" style={{ marginBottom: '10px', marginTop: '20px' }}>
                        <Col  lg="12" md="12" sm="12" xs="12">
                        <button className='btn btn-info' onClick={ () => (setBtnDisabled(false),setShowFormSequnce(false)) }>
                            Cancel
                        </button>
                        <button className='btn btn-primary ml-3' disabled={btnDisabled} onClick={ () => saveSeqeunce() }>
                            { selectJob.mixmax_sequnce_id != '' ? 'Update' : 'Add' }
                        </button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <Modal
                onHide={() => (setShowMsgFormSequnce(false),setBtnDisabled(false))}
                show={showMsgFormSequnce}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                dialogClassName="modal-60w"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="mx-auto text-center">
                        Set Message Sequence
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                    <form >
                        <Row className="w-100">
                            <Col  lg="12" md="12" sm="12" xs="12" className="text-center">
                                <h5>{selectJob.name}</h5>
                            </Col>
                        </Row>
                        <Row className="w-100" style={{ marginBottom: '10px', marginTop: '20px' }}>
                            <Col  lg="12" md="12" sm="12" xs="12">
                                <label>Select Message Sequence :</label>
                                <Select
                                options={msgSequnceList}
                                value={defaultMsgSeq}
                                onChange={onSelecteMsgSequnce}
                                name="msg_sequence"
                                style={colourStyles}
                                menuPosition='fixed'
                                placeholder='Select Message Sequence'
                                isLoading={loadingModal}
                            />
                            {validationError && validationError.msg_sequence && 
                                <label className='error-validation'>
                                {validationError && validationError.msg_sequence} 
                                </label>
                            }
                            </Col>
                        </Row>
                        <br/>
                    </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="w-100 text-right" style={{ marginBottom: '10px', marginTop: '20px' }}>
                        <Col  lg="12" md="12" sm="12" xs="12">
                        <button className='btn btn-info' onClick={ () => (setBtnDisabled(false),setShowMsgFormSequnce(false)) }>
                            Cancel
                        </button>
                        <button className='btn btn-primary ml-3' disabled={btnDisabled} onClick={ () => saveMsgSeqeunce() }>
                            { selectJob.mixmax_sequnce_id != '' ? 'Update' : 'Add' }
                        </button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </Card>
    )
}

export default JobsIndex