import React, { useState, useEffect, useReducer, useRef } from 'react'
import FilterStack from '../FilterStack/FilterStack'
import styles from './styles/CandidateManager.module.scss'
import axios from 'axios'
import uniqBy from 'lodash.uniqby'
import isNil from 'lodash.isnil'
import Alert from 'react-bootstrap/Alert'
import CandidateTable from '../CandidateTable/CandidateTable'
import ScheduleModal from '../ScheduleModal/ScheduleModal'
import RecruitingActivity from '../RecruitingActivity/RecruitingActivity'
import CandidateInfoPanel from '../CandidateInfoPanel/CandidateInfoPanel'
import EmailClient from '../EmailClient/EmailClient'
import { nanoid } from 'nanoid'
import Button from 'react-bootstrap/Button'
import { CSVLink } from 'react-csv'
import _ from 'lodash'
import isEmpty from 'lodash.isempty'
import { makeRequest } from '../RequestAssist/RequestAssist'
import {
    initialState,
    reducer,
    StoreDispatchContext,
} from '../../../stores/JDPStore'
import './styles/ToggleSwitch.scss'
import Util from '../../../utils/util'
import SearchBarCandidate from '../SearchBarCandidate/SearchBarCandidate'
import RenderInterviewScheduleDetails from '../../common/ScheduleInterviewDetails/RenderInterviewScheduleDetails'
import Select from 'react-select'
import { set } from 'react-ga'
import Modal from 'react-bootstrap/Modal'
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();


const ALL_LEADS = 'all_leads'
// const MY_LEADS = 'my_leads'
// const TEAMS_LEADS = 'team_leads'

function CandidateManager(props) {
   const {
        user,
        candidateSource,
        title = '',
        jobId = -1,
        fullJob,
        tableColumns,
        showSearchField = false,
        enableStages = true,
        allowCandidateUpload = false,
        isEmailConfigured,
        stage,
        showUploadCandidatePanel,
        setShowUploadCandidatePanel,
        currentOrganization,
        candidatePage,
        memberOrganization,
        placeholder,
        showdisplaycount=true,
        filterStack,
        setFilterStack,
        emptyFilter,
        setFilterValue,
        filterValue,
        isFromCandidateSearchPage,
        ctRecruitersList,
        isMonthOrganizationLimitExp=false,
        showCandidate,
        showCandidateModal,
        organization,
        pageName,
        ctMember,
        freeCandidates,
        setstageCount,
        setMatchCount
    } = props
    let page_no = candidateSource == 'candidate_search' ? localStorage.getItem("candidate_search_page_no") : ''
    const [loading, setLoading] = useState(false)
    const [totalCandidateCount, setTotalCandidateCount] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    // const [page, setPage] = useState((candidateSource == 'candidate_search' && page_no) ? JSON.parse(page_no) : 0)
    const [page, setPage] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [showScheduleModal, setShowScheduleModal] = useState(false)
    const [allSelectedCandidates, setAllSelectedCandidates] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [interviewModal,setInterviewModal] =useState([])
    const [successFormSubmitting, setSuccessFormSubmitting] = useState('')
    const [reloadCandidateData, setReloadCandidateData] = useState(false)
    const [validationErrors, setValidationErrors] = useState({})
    const csvLink = useRef()
    const [state, dispatch] = useReducer(reducer, initialState)
    const [apiCallFrom, setApiCallFrom] = useState('search')
    const [totalPersonCount, setTotalPersonCount] = useState(0)
    const [haveFilter, setHaveFilter] = useState(false)
    const [transactionData, setTransactionData] = useState([])
    const [listEmailSendCandidate, setListEmailSendCandidate] = useState([])
    const [showHistory, setShowHistory] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [isAIGenerateFilterSet, setIsAIGenerateFilterSet] = useState(false)
    const [filterOptions, setFilterOptions] = useState([])
    const [filterLoadData, setFilterLoadData] = useState([])
    const [filterLoading, setFilterLoading] = useState(false)
    const [selectedAction, setSelectedAction] = useState('3')
    const [currentCandidates,setCurrentCandidates] = useState(0)
    const [defaultActionselected, setdefaultActionselected] = useState({ value: '3', label: 'Last 3 Months' })
    const [actionLists, setActionLists] = useState([{ value: '3', label: 'Last 3 Months' }, { value: '2', label: 'Last 2 Months' }, { value: '1', label: 'Last 1 Month' }, { value: '2w', label: 'Last 2 Weeks' }, { value: '1w', label: 'Last 1 Week' }])
    const colourStyles = {
        control: styles => ({ ...styles, height: 'auto',backgroundColor: '#F6F7FC',marginLeft:'10px',border: 'none',minHeight:'40px', width: '150px' })
    };
    const [sortConfig, setSortConfig] = useState({ key: 'match', direction: 'desc' });

    const [inputTopFilterValue, setInputTopFilterValue] = useState({
        compnay_top: '',
        school_top: '',
        skill_top: '',
        title_top: '',
        city_top: '',
        state_top: '',
        email_top: '',
        name_top: '',
        location_top: ''
    });
    
    const [selectedTopFilter, setSelectedTopFilter] = useState({
        compnay_top: [],
        school_top: [],
        skill_top: [],
        title_top: [],
        city_top: [],
        state_top: [],
        email_top: [],
        name_top: [],
        location_top: []
    });
    
    const handleInputTopFilter = (name, value) => {
        setInputTopFilterValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    
    const handleKeyDown = (name, event) => {
        if (!inputTopFilterValue[name]) return;
    
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                const newOption = { label: inputTopFilterValue[name], value: inputTopFilterValue[name] };
                setSelectedTopFilter((prev) => ({
                    ...prev,
                    [name]: [...prev[name], newOption],
                }));
                setInputTopFilterValue((prev) => ({
                    ...prev,
                    [name]: '',
                }));
                event.preventDefault();
                break;
            default:
                break;
        }
    }

    // Feature flag
    const showRecruitingFilters = true
    const candidateManagerId = nanoid()
    const setFilterData = (attr, val) => {
        let newFilterStack = { ...filterStack, [attr]: val }
        if(attr == 'match_skill'){
            if(val){
                newFilterStack = { ...filterStack, [attr]: val, skills: fullJob.skills}
            }else{
                newFilterStack = { ...filterStack, [attr]: val, skills: []}
            }
        }
        if(attr == 'city' || attr == 'state'){
            if(val){
                newFilterStack = { ...filterStack, [attr]: val,locations: val}
            }else{
                newFilterStack = { ...filterStack, [attr]: val,locations: []}
            }
        }
        if(!candidatePage && stage !== 'lead')
            // delete newFilterStack.keyword
        if(!_.isEqual(filterStack,newFilterStack)) {
            setFilterStack(newFilterStack)
        }
    }
    const refreshCandidateData = async () => {
        // window.scrollTo(0, 0)
        setLoading(true)
        let page_num = (candidateSource == 'candidate_search' && page) ? page : 0
        if(apiCallFrom === 'pagination') {
            page_num = page
        } else {
            setPage(0)
            setApiCallFrom('search')
            page_num = 0
        }
        const result = await fetchCandidates(
            candidateSource,
            page_num,
            null,
            jobId,
            formatFiltersForSearch(filterStack),
            stage,
            showHistory,
            selectedAction,
            sortConfig
        )

        dispatch({
            type: 'set_candidates',
            candidates: result.candidates,
            candidate_history: result.candidate_history
        })
        if(candidateSource == 'submitted_candidates'){
            setstageCount(result.job_stage_count)
            setMatchCount(result.job_match_count)
        }
        // console.log(result)
        setTotalCandidateCount(result.totalCandidateCount)
        setPageCount(result.pageCount)
        setTotalPersonCount(result.total_persons)
        setCurrentCandidates(result?.currentCount)
        setLoading(false)
    }

    useEffect(() => {
        (async () => {
            // window.scrollTo(0, 0)
            const allSelectedCandidates = await fetchAllSelectedCandidates(
                state,
                filterStack,
                candidateSource,
                jobId,
                stage,
                showHistory,
                selectedAction
            )
            setAllSelectedCandidates(allSelectedCandidates)
        })()
    }, [state.selectedCandidates, filterStack, candidateSource, jobId, stage, selectedAction])

    const handleAction = (event) => {
        setSelectedAction(event.value)
    }

    const prevValues = useRef({
        sortConfig,
        filterStack
    });


    useEffect(() => {
        setApiCallFrom('search')
        refreshCandidateData()
        console.log('refreshCandidateData')
    }, [filterStack, page, stage, reloadCandidateData,showHistory, selectedAction])

    // deselect everyone when filters are modified
    useEffect(() => {
        dispatch({ type: 'deselect_all' })
    }, [filterStack])

    useEffect(() => {
        dispatch({ type: 'set_user', user })
        getSaveFilter()
        getSendEmailList()
    }, [])

    const handleModal = () => {
        setShowScheduleModal(false)
    }

    const handleInterviewModal = (value) => {
       setInterviewModal(value)
       if(!modalShow){
            setModalShow(true)
        }
    }
      
    const interviewDetails = () => {
      return(
            <RenderInterviewScheduleDetails
              show={modalShow}
              hideModal={() => setModalShow(false)}
              interviewModal={interviewModal}
            />
      )
    }

    const loadSaveFilterDara = (data) =>{
        setFilterLoadData(data)
        setFilterOptions([...data.map((val) => ({ value: val.id, label:val.search_name }))]);
        setFilterLoading(false)
    } 
    const handleHistory = (e) =>{
        setShowHistory(e.target.checked)
        dispatch({ type: 'handle_history' })
    }
    const getSaveFilter = () =>{
        setFilterLoading(true)
         axios.post(`/jobs/${jobId}/saved_filters`)
        .then((r) => loadSaveFilterDara(r.data))
        .catch((e) => console.log(e))
    }
    const getSendEmailList = () =>{
        if(!isFromCandidateSearchPage){
            axios.post(`/jobs/${jobId}/send_emails`)
            .then((r) => setListEmailSendCandidate(r.data.data))
            .catch((e) => console.log(e))
        }
    }
    useEffect(() => {
        let arr = []
        Object.keys(filterStack).map(function(key, inex) {
            if(typeof filterStack[key] == "boolean") {
                filterStack[key] ? arr.push(true) : arr.push(false)
            } else {
                filterStack[key]?.length > 0 ? arr.push(true) : arr.push(false)
            }
        })
        arr.includes(true) ? setHaveFilter(true) : setHaveFilter(false)
    }, [filterStack])

    function renderDisplayCount(){
        return(
            <>

            <span className={candidatePage ?  styles.candidatecount : styles.resultCount}>
                Displaying{' '}
                {Util.displayNumberOfResults(
                    totalCandidateCount,
                    pageCount,
                    page,
                    25, // candidates per page,
                    totalCandidateCount >= 10000 ? `${totalCandidateCount}+` : totalCandidateCount,
                    true,
                    showdisplaycount
                )}
            </span>
            </>
        )
    }

    function handlePageChangeClick(page_num) {
        setPage(page_num)
        if (candidateSource == 'candidate_search')
          pageName == 'free' ? "" :  localStorage.setItem("candidate_search_page_no",page_num)
        setApiCallFrom('pagination')
    }

    const sortedData = React.useMemo(() => {
        const changes = [];
        if (prevValues.current.sortConfig.direction !== sortConfig.direction) {
            changes.push('sortConfig');
        }
        if (changes.length > 0) {
            console.log('Changed values:', changes);
            setApiCallFrom('search')
            refreshCandidateData()
        }
        // Update previous values
        prevValues.current = {
            sortConfig,
            filterStack
        };        
    }, [sortConfig]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const onAIFilterApply = async () => {
        var companyValues = ''
        var titleValues = ''
        var schoolValues = ''
        var skillValues = []
        var cityValues = []
        var stateValues = []
        var locationValues = []
        if (selectedTopFilter.title_top && selectedTopFilter.title_top.length > 0) {
            titleValues = selectedTopFilter.title_top.map(option => option.value).join(',');
        }
        if (selectedTopFilter.compnay_top && selectedTopFilter.compnay_top.length > 0) {
            companyValues = selectedTopFilter.compnay_top.map(option => option.value).join(',');
        }
        if (selectedTopFilter.school_top && selectedTopFilter.school_top.length > 0) {
            schoolValues = selectedTopFilter.school_top.map(option => option.value).join(',');
        }
        if (selectedTopFilter.skill_top && selectedTopFilter.skill_top.length > 0) {
            skillValues = selectedTopFilter.skill_top.map(option => option.value);
        }
        if (selectedTopFilter.city_top && selectedTopFilter.city_top.length > 0) {
            cityValues = selectedTopFilter.city_top
        }
        if (selectedTopFilter.state_top && selectedTopFilter.state_top.length > 0) {
            stateValues = selectedTopFilter.state_top
        }
        if(stateValues.length > 0){
            locationValues = stateValues
        }
        if(cityValues.length > 0){
            locationValues = cityValues
        }
        var newFilter = {...filterStack, 'titles': titleValues, 'company_names': companyValues, 'schools': schoolValues, 'skills': skillValues, 'city': cityValues, 'state': stateValues, 'locations': locationValues }
        setFilterStack(newFilter);
        setShowFilterModal(false)
        setIsAIGenerateFilterSet(true)
    }

    const onResetAIGenerateFilter = (e) => {
        setSelectedTopFilter({
            compnay_top: [],
            school_top: [],
            skill_top: [],
            title_top: [],
            city_top: [],
            state_top: [],
            email_top: [],
            name_top: [],
            location_top: []
        })
        setIsAIGenerateFilterSet(false)
        setSearchText('')
        e.preventDefault()
        const filter = { ...emptyFilter }
        filter.keyword = ''
        setFilterStack(filter)
    }
    
    const generateAISearchFilter = async (searchText) => {
        if (searchText != '') {

            setSelectedTopFilter({
                compnay_top: [],
                school_top: [],
                skill_top: [],
                title_top: [],
                city_top: [],
                state_top: [],
                email_top: [],
                name_top: [],
                location_top: []
            })
            const payload = new FormData();
            payload.append('searchText', searchText);
            let url = "/get_natural_query";
            try {
                const result = await makeRequest(url, 'post', payload);
                const response = result.data;
                if (response.success) {
                    const dataParse = JSON.parse(response.result);
                    let newOptions = {};
                    if (dataParse?.job_title) {
                        if (Array.isArray(dataParse.job_title)) {
                            newOptions.title_top = dataParse.job_title.map(title => ({ label: title, value: title }));
                        } else {
                            newOptions.title_top = [{ label: dataParse.job_title, value: dataParse.job_title }];
                        }
                    }
    
                    if (dataParse?.skills) {
                        if (Array.isArray(dataParse.skills)) {
                            newOptions.skill_top = dataParse.skills.map(skill => ({ label: skill, value: skill }));
                        } else {
                            newOptions.skill_top = [{ label: dataParse.skills, value: dataParse.skills }];
                        }
                    }

                    if (dataParse?.university) {
                        if (Array.isArray(dataParse.university)) {
                            newOptions.school_top = dataParse.university.map(university => ({ label: university, value: university }));
                        } else {
                            newOptions.school_top = [{ label: dataParse.university, value: dataParse.university }];
                        }
                    }

                    if (dataParse?.city) {
                        if (Array.isArray(dataParse.city)) {
                            newOptions.city_top = dataParse.city.map(city => ({ label: city, value: city }));
                        } else {
                            newOptions.city_top = [{ label: dataParse.city, value: dataParse.city }];
                        }
                    }

                    if (dataParse?.state) {
                        if (Array.isArray(dataParse.state)) {
                            newOptions.state_top = dataParse.state.map(state => ({ label: state, value: state }));
                        } else {
                            newOptions.state_top = [{ label: dataParse.state, value: dataParse.city }];
                        }
                    }

                    if (dataParse?.company) {
                        if (Array.isArray(dataParse.company)) {
                            newOptions.compnay_top = dataParse.company.map(state => ({ label: state, value: state }));
                        } else {
                            newOptions.compnay_top = [{ label: dataParse.company, value: dataParse.company }];
                        }
                    }
    
                    setSelectedTopFilter(prev => ({
                        ...prev,
                        ...(newOptions.title_top ? { title_top: [...prev.title_top, ...newOptions.title_top] } : {}),
                        ...(newOptions.skill_top ? { skill_top: [...prev.skill_top, ...newOptions.skill_top] } : {}),
                        ...(newOptions.compnay_top ? { compnay_top: [...prev.compnay_top, ...newOptions.compnay_top] } : {}),
                        ...(newOptions.school_top ? { school_top: [...prev.school_top, ...newOptions.school_top] } : {}),
                        ...(newOptions.city_top ? { city_top: [...prev.city_top, ...newOptions.city_top] } : {}),
                        ...(newOptions.state_top ? { state_top: [...prev.state_top, ...newOptions.state_top] } : {}),
                    }));
    
                    setShowFilterModal(true);
                } else {
                    console.error("Error: ", response.error);
                }
            } catch (e) {
                console.error("Request failed: ", e);
            }
        }else{
            setSelectedTopFilter({
                compnay_top: [],
                school_top: [],
                skill_top: [],
                title_top: [],
                city_top: [],
                state_top: [],
                email_top: [],
                name_top: [],
                location_top: []
            })
            setIsAIGenerateFilterSet(false)
            setSearchText('')
            const filter = { ...emptyFilter }
            filter.keyword = ''
            setFilterStack(filter)
        }
    }

    const handleImport = async () => {
        if(isEmpty(allSelectedCandidates)){
            setValidationErrors({
                ...validationErrors,
                selectedcandidated: 'Please select candidate',
            })
            return
        }
        
        const candidates = allSelectedCandidates
        const candidateIds = candidates.map((candidate) => candidate.id)
        const payload = new FormData()

        payload.append('list_of_recipient_ids', candidateIds)
        let url = "/people/import_candidate"
        const result = await makeRequest(url, 'post', payload)
        .then((r) => setTransactionData(r.data))
        .catch((e) => console.log(e))
        csvLink.current.link.click()
    }   
   const searchCandidate = state?.candidates
    const freeCandidate = searchCandidate?.filter((item) => item?.source == 'linkedin_auto')
    return (
        <StoreDispatchContext.Provider value={{ state, dispatch }}>
            {successFormSubmitting && (
                <Alert
                    style={{ flexGrow: '1' }}
                    variant="success"
                    onClose={() =>
                        setSuccessFormSubmitting(null)
                    }
                    dismissible
                >
                    {successFormSubmitting}
                </Alert>
            )}
            <div className={styles.mainContainer}>
                {/* Filters column */}
                <div className={styles.filtersColumn}>
                    {/* {showRecruitingFilters && !candidatePage && (
                        <RecruitingActivity
                            setSelectWithinValue={(val) => {
                                setFilterData('withinPeriodKey', val)
                            }}
                        />
                    )} */}
                    <FilterStack
                        candidatePage={candidatePage}
                        filterStack={filterStack}
                        setFilterData={setFilterData}
                        setFilterStack={setFilterStack}
                        emptyFilter={emptyFilter}
                        currentOrganization={currentOrganization}
                        memberOrganization={memberOrganization}
                        currentUser={user}
                        jobId={jobId}
                        closeFunc={
                            showUploadCandidatePanel === undefined
                                ? () => {
                                      dispatch({ type: 'hide_candidate' })
                                  }
                                : () => {
                                      setShowUploadCandidatePanel(false)
                                      dispatch({ type: 'hide_candidate' })
                                  }
                        }
                        filterOptions={filterOptions}
                        filterLoading={filterLoading}
                        filterLoadData={filterLoadData}
                        setFilterValue={setFilterValue}
                        filterValue={filterValue}
                        getSaveFilter={getSaveFilter}
                        stage={stage}
                    />
                </div>
                
                {/* Candidates table column */}
                <div className={styles.tableColumn}>
                    <div className={candidatePage ? '' : styles.tableContainer}>
                        <div className={styles.tableHeader}>
                            <div className={styles.tableTitleBlock}>
                                {stage !== 'lead' ? (
                                    <h3 className={styles.tableTitle}>
                                        {title}
                                    </h3>
                                ) : (
                                    ''
                                )}
                               {candidatePage ? ' ' : renderDisplayCount()}
                            </div>
                            {showSearchField && (
                                <SearchBarCandidate
                                    candidatePage={candidatePage}
                                    hideButton={!candidatePage}
                                    placeholder={placeholder}
                                    value={searchText}
                                    setValue={setSearchText}
                                    onClick={(e) => {
                                        // setSearchText(searchText)
                                        }
                                    }
                                    onEnterPressed={(event) => {
                                        setPage(0)
                                        // setFilterData('keyword', searchText)
                                        generateAISearchFilter(searchText)
                                    }}
                                    onCancelPressed={(event) => {
                                        // setSearchText('')
                                        // setPage(0)
                                        // setFilterData('keyword', '')
                                    }}
                                    onChange={(event) => {
                                        const text = event.target.value
                                        setSearchText(text)

                                        // We don't want to trigger a change to filterStack on every character
                                        // change since a new search would be performed each time, but we
                                        // want filterStack['keyword'] to have the latest value at all times.
                                    }}
                                    candidateSource={candidateSource}
                                    onResetFilterPressed={ (e) => {
                                        onResetAIGenerateFilter(e)
                                    }}
                                    onUpdateAIFilterPressed={ (e) => {
                                        setShowFilterModal(true)
                                    }}
                                    isAIGenerateFilterSet={isAIGenerateFilterSet}  
                                    style={{with: '80%'}}
                                />
                            )}
                            <div className="d-flex"> 
                           
                                {enableStages && 
                                <>
                                    <CSVLink
                                    data={transactionData}
                                    filename='transactions.csv'
                                    className='hidden'
                                    ref={csvLink}
                                    target='_blank'
                                    />
                                    <Button className={styles.linkedInButton} onClick={handleImport} disabled={allSelectedCandidates.length <= 0} style={{fontWeight: '800',width:'138px',fontSize:'12px'}}> Export Linkedin URL </Button>
                                    {stage === 'active_candidates'&&
                                        <label className="switch"  style={{fontWeight: '800',width:'82px',fontSize:'12px'}}>
                                            <input type="checkbox" id="togBtn" onClick={handleHistory} />
                                                <div className="slider round">
                                                <span className="on">Hide</span>
                                                <span className="off">Show</span>
                                            </div>
                                        </label>
                                    }
                                </>
                                }
                                {stage == 'active_candidates' && (
                                    <Select
                                    defaultValue={defaultActionselected}
                                    options={actionLists}
                                    onChange={(event) => handleAction(event)}
                                    name="select_action"
                                    styles={colourStyles}
                                />
                                )}
                            </div>
                        </div>
                        <CandidateTable
                            candidatePage={candidatePage}
                            displayCount={renderDisplayCount()}
                            columns={tableColumns}
                            loading={loading}
                            candidates={searchCandidate}
                            page={page}
                            jobId={jobId}
                            stage={stage}
                            handlePageChangeClick={handlePageChangeClick}
                            requestSort={requestSort}
                            sortConfig={sortConfig}
                            totalPages={pageCount}
                            enableStages={enableStages}
                            totalCandidateCount={totalCandidateCount}
                            refreshCandidates={refreshCandidateData}
                            allSelectedCandidates={allSelectedCandidates}
                            jobs={fullJob}
                            candidateSource={candidateSource}
                            memberOrganization={memberOrganization}
                            currentOrganization={currentOrganization}
                            candidate_history={state.candidate_history}
                            filterStack={filterStack}
                            showCandidate={showCandidate}
                            organization={organization}
                            ctMember={ctMember}
                            freeCandidates={freeCandidates}
                            listEmailSendCandidate={listEmailSendCandidate}
                        />
                    </div>
                </div>
            </div>
            { showScheduleModal && (
                <ScheduleModal
                    show={showScheduleModal}
                    onHide={handleModal}
                    onInterview={handleInterviewModal}
                    user={state.user ?? { id: -1 }}
                    candidate={state.displayedCandidate}
                    jobs={fullJob}
                    fullJob={fullJob}
                />
            )}
            {showSearchField && (
                <Modal
                show={showFilterModal}
                className="filterModal"
                aria-labelledby="contained-modal-title-vcenter"
                backdropClassName={styles.modalBackdrop}
                size="lg"
                centered
                >
                    <Modal.Header>
                            <div className="d-flex flex-column p-1">
                                <p  style={{fontSize: '22px',marginBottom: '2px'}}>Search Filters</p>
                                <span style={{fontSize: '13px'}}>Apply the manual filter with different parameters.</span>
                            </div>
                    </Modal.Header>
                    <Modal.Body className={styles.modalBody}>
                        <div className={styles.titleSection} style={{background: '#f5f5f5'}}>
                           <div className='d-flex pb-3 pt-3' >
                                <div className='col-lg-6' >
                                    <label>Company:</label>
                                    <Select
                                        components={animatedComponents}
                                        inputValue={inputTopFilterValue.compnay_top}
                                        name="compnay_top"
                                        isMulti
                                        menuIsOpen={false}
                                        onChange={(newValue) => setSelectedTopFilter((prev) => ({ ...prev, compnay_top: newValue }))}
                                        onInputChange={(value) => handleInputTopFilter('compnay_top', value)}
                                        onKeyDown={(event) => handleKeyDown('compnay_top', event)}
                                        placeholder="Example: Google Mircrosoft Facebook"
                                        value={selectedTopFilter.compnay_top}
                                        options={[]}
                                    />
                                </div>
                                <div className='col-lg-6'>
                                    <label>School / University:</label>
                                        <Select
                                            components={animatedComponents}
                                            inputValue={inputTopFilterValue.school_top}
                                            name="school_top"
                                            isMulti
                                            menuIsOpen={false}
                                            onChange={(newValue) => setSelectedTopFilter((prev) => ({ ...prev, school_top: newValue }))}
                                            onInputChange={(value) => handleInputTopFilter('school_top', value)}
                                            onKeyDown={(event) => handleKeyDown('school_top', event)}
                                            placeholder="Example: Stanford"
                                            value={selectedTopFilter.school_top}
                                            options={[]}
                                        />
                                </div>
                            </div>
                            <div className='d-flex pb-3 pt-3' >
                                <div className='col-lg-6'>
                                    <label>Skills:</label>
                                        <Select
                                            components={animatedComponents}
                                            inputValue={inputTopFilterValue.skill_top}
                                            name="skill_top"
                                            isMulti
                                            menuIsOpen={false}
                                            onChange={(newValue) => setSelectedTopFilter((prev) => ({ ...prev, skill_top: newValue }))}
                                            onInputChange={(value) => handleInputTopFilter('skill_top', value)}
                                            onKeyDown={(event) => handleKeyDown('skill_top', event)}
                                            placeholder="Example: ROR Sales Marketing"
                                            value={selectedTopFilter.skill_top}
                                            options={[]}
                                        />
                                </div>
                                <div className='col-lg-6'>
                                    <label>Job Titles:</label>
                                        <Select
                                            components={animatedComponents}
                                            inputValue={inputTopFilterValue.title_top}
                                            name="title_top"
                                            isMulti
                                            menuIsOpen={false}
                                            onChange={(newValue) => setSelectedTopFilter((prev) => ({ ...prev, title_top: newValue }))}
                                            onInputChange={(value) => handleInputTopFilter('title_top', value)}
                                            onKeyDown={(event) => handleKeyDown('title_top', event)}
                                            placeholder="Example: Software Engineer"
                                            value={selectedTopFilter.title_top}
                                            options={[]}
                                        />
                                </div>
                           </div>
                           <div className='d-flex pb-3 pt-3' >
                                <div className='col-lg-6'>
                                    <label>City:</label>
                                        <Select
                                            components={animatedComponents}
                                            inputValue={inputTopFilterValue.city_top}
                                            name="city_top"
                                            isMulti
                                            menuIsOpen={false}
                                            onChange={(newValue) => setSelectedTopFilter((prev) => ({ ...prev, city_top: newValue }))}
                                            onInputChange={(value) => handleInputTopFilter('city_top', value)}
                                            onKeyDown={(event) => handleKeyDown('city_top', event)}
                                            placeholder="Example: California"
                                            value={selectedTopFilter.city_top}
                                            options={[]}
                                        />
                                </div>
                                <div className='col-lg-6'>
                                    <label>State:</label>
                                        <Select
                                            components={animatedComponents}
                                            inputValue={inputTopFilterValue.state_top}
                                            name="state_top"
                                            isMulti
                                            menuIsOpen={false}
                                            onChange={(newValue) => setSelectedTopFilter((prev) => ({ ...prev, state_top: newValue }))}
                                            onInputChange={(value) => handleInputTopFilter('state_top', value)}
                                            onKeyDown={(event) => handleKeyDown('state_top', event)}
                                            placeholder="Example: United states"
                                            value={selectedTopFilter.state_top}
                                            options={[]}
                                        />
                                </div>
                           </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='pt-5 pr-5'>
                        <Button variant="primary"  onClick={ () => onAIFilterApply(false) } >
                            Apply Filter
                        </Button>
                        <Button variant="light" onClick={ () => setShowFilterModal(false) }>
                        Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            {modalShow &&  interviewDetails()}
            {(!isMonthOrganizationLimitExp || pageName == 'free') &&
                <CandidateInfoPanel
                    user = {user}
                    candidate={state.displayedCandidate}
                    closeFunc={
                        showUploadCandidatePanel === undefined
                            ? () => {
                                dispatch({ type: 'hide_candidate' })
                            }
                            : () => {
                                setShowUploadCandidatePanel(false)
                                dispatch({ type: 'hide_candidate' })
                            }
                    }
                    handleScheduleButtonClick={() => setShowScheduleModal(true)}
                    showUploadCandidatePanel={showUploadCandidatePanel}
                    memberOrganization={memberOrganization}
                    page={page}
                    setPage={setPage}
                    pageCount={pageCount}
                    jobId={jobId}
                    setApiCallFrom={setApiCallFrom}
                    currentOrganization={currentOrganization}
                    reloadCandidateData={reloadCandidateData}
                    setReloadCandidateData={setReloadCandidateData}
                    allowCandidateUpload={allowCandidateUpload}
                    recruitersList={ctRecruitersList || []}
                    jobStatus={fullJob?.status}
                    showCandidateModal={showCandidateModal}
                    listEmailSendCandidate={listEmailSendCandidate}
                />
            }
            {!state.displayedCandidate && candidateSource != 'candidate_search' &&
              <EmailClient
                  emailClientId={'emailclientfor_' + candidateManagerId}
                  userId={user?.id}
                  isEmailConfigured={isEmailConfigured}
                  jobId={jobId}
                  userEmail={user?.integrated_email ? user?.integrated_email : user?.email}
                  showByDefault={false}
                  mailSentCallback={() => refreshCandidateData()}
                  sendList={allSelectedCandidates}
                  candidateCount={state.candidates}
                  // totalCandidateCount={totalCandidateCount}
                  setSuccessFormSubmitting={setSuccessFormSubmitting}
                  successFormSubmitting={successFormSubmitting}
                  listEmailSendCandidate={listEmailSendCandidate}
              />
            }
        </StoreDispatchContext.Provider>
    )
}

const fetchCandidates = async (
    candidateSource,
    page,
    count,
    jobId,
    filters,
    stage,
    showHistory,
    selectedAction,
    sortConfig
) => {
    // Local pagination is 0-based, but API is 1-based
    if (!isNil(page)) {
        page += 1
    }

    if (candidateSource === 'submitted_candidates') {
        return await fetchApplicants(page, count, jobId, stage, filters,showHistory, selectedAction,sortConfig)
    } else if (candidateSource === 'lead_candidate_search') {
        return await fetchCandidatesFromSearch(
            page,
            count,
            jobId,
            filters,
            true
        )
    } else if (candidateSource === 'candidate_search') {
        return await fetchCandidatesFromSearch(
            page,
            count,
            jobId,
            filters,
            false
        )
    } else {
        return { candidates: [] }
    }
}

const fetchApplicants = async (page, count, jobId, stage, filters,showHistory, selectedAction,sortConfig) => {
    filters['sortField'] = sortConfig.key
    filters['sortDirection'] = sortConfig.direction
    const payload = JSON.stringify({
        filters,
        page: !isNil(page) ? page : undefined,
        count: !isNil(count) ? count : undefined,
        job_id: jobId,
        period_key: filters.withinPeriodKey,
        stage,
        show_history:showHistory,
        selected_action: selectedAction
    })
    const CSRF_Token = document
    .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
    const response = await axios.post(
        '/jobs/fetch_submitted_candidates',
        // '/jobs/fetch_submitted_candidates?sortField='+sortConfig.key+'&sortDirection='+sortConfig.direction,
        payload,
        {
            headers: {
                'content-type': 'application/json',
                'X-CSRF-Token': CSRF_Token,
            },
        }
        )
        const uniqCandidates = uniqBy(response.data.submitted_candidates, 'id')
        .filter((person) => isNil(stage) || person.stage === stage)
        .map((person) => transformPersonModel(person))
        return {
            candidate_history: response.data.candidate_history,
            candidates: response.data.submitted_candidates,
            totalCandidateCount: response.data.total_count, // WTODO need something along these lines that takes into account stages: response.data.total_count,
            pageCount: response.data.total_pages || 1,
            job_stage_count: response.data.job_stage_count,
            job_match_count: response.data.job_match_count
        }
}

const fetchCandidatesFromSearch = async (
    page,
    count,
    jobId,
    filters,
    with_score
) => {
    let new_location = []
    if(filters.city)
    {
        const cities = filters.city.split(',')
        cities.map((ct, i)=>{
        if(i == 0) {
            new_location.push(ct)
        } else if(i%3 == 0) {
            new_location.push(ct)
        }

        })
        filters.locations = new_location.join(',')
    } else if(filters.state) {
        filters.locations = filters.state
    }
    const payload = JSON.stringify({
        filters,
        page: !isNil(page) ? page : undefined,
        count: !isNil(count) ? count : undefined,
        job_id: jobId,
        period_key: filters.withinPeriodKey,
        with_score: with_score,
    })

    const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')

    const response = await axios.post('/people/search', payload, {
        headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': CSRF_Token,
        },
    })
     
    const uniqCandidates = uniqBy(response.data.people, 'id').map((person) => {
        return transformPersonModel(person)
    })

    return {
        candidate_history: response.data.candidate_history,
        candidates: uniqCandidates,
        currentCount: uniqCandidates.length,
        totalCandidateCount: response.data.total,
        pageCount: response.data.total_pages,
        total_persons: response.data.total_persons
    }
}

/**
 * Fetches an arbitrary number of candidates, potentially spanning multiple pages.
 * @param {*} n The number of candidates to fetch
 * @param {*} filterStack The filters to use
 * @param {*} candidateSource Where we are fetching from (this is an enum value)
 * @param {*} jobId The job candidates are associated with
 */
const fetchNCandidates = async (
    n,
    filterStack,
    candidateSource,
    jobId,
    stage
) => {
    if (n === 0) return []

    return (
        (
            await fetchCandidates(
                candidateSource,
                null,
                n,
                jobId,
                formatFiltersForSearch(filterStack),
                stage,
                sortConfig
            )
        ).candidates ?? []
    )
}

const fetchAllSelectedCandidates = async (
    state,
    filterStack,
    candidateSource,
    jobId,
    stage,
    showHistory
) => {
    // const numCandidates = Math.min(
    //     state.selectionLimit,
    //     state.selectedCandidates.length
    // )
    // const fullCandidateData = await fetchNCandidates(
    //     numCandidates,
    //     filterStack,
    //     candidateSource,
    //     jobId,
    //     stage,
    //     showHistory
    // )

    // const allSelectedCandidates = fullCandidateData.filter(
    //     (candidate, i) => state.selectedCandidates[i]
    // )
    let allSelectedCandidates = []
    // We only fetch full candidate data up to state.selectionLimit above,
    // so here we add in any candidates which were selected outside the
    // selection limit.
    if(state.selectAllChecked){
        allSelectedCandidates = state.candidates
    }else{
        for (   
            let i = 0;
            i < state.selectedCandidates.length;
            i++
        ) {
            if (state.selectedCandidates[i]) {
                allSelectedCandidates.push(state.candidates[i])
            }
        }
    }
    return allSelectedCandidates
}

function transformPersonModel(model) {
    // Replace null/undefined on person models with empty strings to avoid
    // unnecessary null pointers
    Object.keys(model).forEach((key) => {
        if (isNil(model[key])) {
            model[key] = ''
        }
    })

    const charsToStrip = /\[|\]|\"/g

    return {
        ...model,
        company_names: model.company_names?.replace(charsToStrip, '') ?? '',
        skills: model.skills?.replace(charsToStrip, '') ?? '',
        tags: model.tags?.replace(charsToStrip, '') ?? '',
    }
}

function candidatePassesAllFilters(candidate, filterStack, filterText) {
    const textFilterAttrs = [
        'first_name',
        'last_name',
        'title',
        'location',
        'school',
        'skills',
        'company_names',
    ]
    const passedTextFilter = true
    Util.objectPassesTextFilter(candidate, textFilterAttrs, filterText)
    return passedTextFilter && passesFilterStack(filterStack, candidate)
}

function passesFilterStack(filters, candidate) {
    return Object.values(filters)
        .filter((testFunc) => typeof testFunc === 'function')
        .reduce((passes, testFunc) => passes && testFunc(candidate), true)
}

function formatFiltersForSearch(filterStack) {
    const clone = {}
    Object.keys(filterStack).forEach((key) => {
        if(key === 'companyNames' || key == 'locations' ||key == 'city' || key == 'state'){
            const filter = filterStack[key]
             const values = filter.map(({value})=> value)
             clone[key] = values.toString()
        } else {
            const filter = filterStack[key]
            clone[key] = Array.isArray(filter) ? filter.join(',') : filter
        }
    })
    return clone
}

export default CandidateManager
